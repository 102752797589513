import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useFeatureFlag } from 'LDfeatureFlag';
import { FEATURE_FLAG_KEYS } from 'LDfeatureFlag/constants';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks/hook';
import { useLoaders } from 'redux/hooks/useLoaders';
import { reloadConfig, selectReloadState } from 'redux/slice/configSlice';
import { checkSession } from 'services/core-security/coreSecurity.service';
import { GetTmpConfigResponseInterface } from 'shared-components/auth-provider/useAuthProvider';
import { useFromAlfredUser } from 'shared-components/useFromAlfredUser/useFromAlfredUser';
import { useTimeoutAction } from 'shared-lib/serviceHook/useTimeoutAction';
import { GetConfigRequestInterface, PayrollUiConfigAccess } from 'shared/services/payroll/uiConfigAccess';
import { createQueryKeys } from 'util/queryKeyFactory';
import { commonStaleTime, getApplicationType } from 'util/utility';

export const getConfigurationKeys = createQueryKeys(['get-configuration'], {
  service: (applicationType: number, companyViewType: number, reload: any) => ({
    key: [applicationType, companyViewType, reload],
  }),
});

export const useInvalidateGetConfigurationQuery = () => {
  const applicationType = getApplicationType();
  const { companyViewType } = useFromAlfredUser();
  const reload = useAppSelector(selectReloadState);

  const queryClient = useQueryClient();

  /** Exclusively used for plan selection for FTU & plan upgrade */
  const invalidateGetConfiguration = async () => {
    await queryClient.invalidateQueries(getConfigurationKeys.service(applicationType, companyViewType, reload).key);
    // Get the invalidated query data
    const invalidatedData = await queryClient.getQueryData(
      getConfigurationKeys.service(applicationType, companyViewType, reload).key
    );
    return invalidatedData;
  };

  return { invalidateGetConfiguration };
};

export const useGetConfigurationHook = () => {
  const { feature } = useFeatureFlag();
  const enableHandleErrorOnSessionCheck = feature(FEATURE_FLAG_KEYS.EnableHandleErrorOnSessionCheck);
  const enableHandleErrorOnSessionCheckAndOpenApp = feature(
    FEATURE_FLAG_KEYS.EnableHandleErrorOnSessionCheckAndOpenApp
  );
  const applicationType = getApplicationType();
  const { companyViewType } = useFromAlfredUser();
  const reload = useAppSelector(selectReloadState);
  const [sessionOkay, setSessionOkay] = React.useState(false);
  const { showLoader, hideLoader } = useLoaders();
  const { timeoutWrapper } = useTimeoutAction();
  const dispatch = useAppDispatch();

  const enableThisQueryWhen = React.useMemo(() => {
    return sessionOkay && applicationType !== undefined && companyViewType !== undefined;
  }, [applicationType, companyViewType, sessionOkay]);

  const sessionInstance = async () => {
    if (process.env.NODE_ENV !== 'development') {
      const session = await checkSession();

      if (enableHandleErrorOnSessionCheck) {
        // Skip redirect in case any unusual error occurs: timeout, network error, etc.
        if ((session as unknown as { validationResultList: unknown[] })?.validationResultList?.length > 0) {
          if (enableHandleErrorOnSessionCheckAndOpenApp) setSessionOkay(true);

          return;
        }
      }

      if (!session.hasSession) {
        const redirect_uri = session.baseUrl + encodeURIComponent(window.location.pathname);
        window.location.replace(redirect_uri);
        return;
      } else setSessionOkay(true);
    } else setSessionOkay(true);
  };

  React.useEffect(() => {
    sessionInstance();
  }, [reload]);

  return useQuery({
    queryKey: getConfigurationKeys.service(applicationType, companyViewType, reload).key,
    queryFn: async () => {
      try {
        showLoader();
        const request: GetConfigRequestInterface = {
          applicationType: applicationType,
          companyViewType: companyViewType,
        };
        const response = (await timeoutWrapper(() =>
          PayrollUiConfigAccess.getConfiguration(request)
        )) as GetTmpConfigResponseInterface;
        if ((response && response.validationResults?.validationResultList?.length) ?? 0 > 0) return;
        else return response;
      } catch (error) {
        throw error;
      } finally {
        hideLoader();
      }
    },
    onSuccess: () => {
      dispatch(reloadConfig(false));
    },
    enabled: enableThisQueryWhen,
    staleTime: commonStaleTime,
  });
};
