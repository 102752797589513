import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';

export type IFilterAnnualReport = { payGroupId?: number; year?: number };
export type IAnnualReportPaygroup = { name: string; id: number; payGroupStatusType: number };
export type IFilterAnnualReportMap = {
  paygroup: { [id: number]: IAnnualReportPaygroup };
  year: { [id: number]: boolean };
};
export type IPaygroupToYearMap = {
  [key: number]: number[];
};

export interface IDropdownItem {
  [key: string]: string | number;
}

export interface IAnnualReportModalState {
  generateReportVisible: boolean;
}

export interface IAnnualReportTableFilter {
  paygroupId: null | number;
  year: null | number;
}
interface IAnnualReportsInit {
  filters: IFilterAnnualReport;
  defaultFilters: IFilterAnnualReport;
  filterMaps: IFilterAnnualReportMap;
  paygroupToYearMap: IPaygroupToYearMap;
  filterDataSet: { paygroupMenu: IDropdownItem[]; yearMenu: IDropdownItem[] };
  modalStates: IAnnualReportModalState;
  tableFilter: IAnnualReportTableFilter;
  isTableFilterApplied: boolean;
}

const initialState: IAnnualReportsInit = {
  filters: {},
  defaultFilters: {},
  filterDataSet: { paygroupMenu: [], yearMenu: [] },
  paygroupToYearMap: {},
  modalStates: {
    generateReportVisible: false,
  },
  filterMaps: {
    paygroup: {},
    year: {},
  },
  tableFilter: {
    paygroupId: null,
    year: null,
  },
  isTableFilterApplied: false,
};

export const annualReportSlice = createSlice({
  name: 'annualReports',
  initialState,
  reducers: {
    updateFilters: (state, action: PayloadAction<IAnnualReportsInit['filters']>) => {
      state.filters = action.payload;
    },
    updateDefaultFilters: (state, action: PayloadAction<IAnnualReportsInit['filters']>) => {
      state.defaultFilters = action.payload;
    },
    updateFilterMap: (state, action: PayloadAction<IAnnualReportsInit['filterMaps']>) => {
      state.filterMaps = action.payload;
    },
    updateToInitialState: () => {
      return initialState;
    },
    updateModalState: (state, action: PayloadAction<{ key: keyof IAnnualReportModalState; value: boolean }>) => {
      state.modalStates[action.payload.key] = action.payload.value;
    },
    updateTableFilter: (state, action: PayloadAction<IAnnualReportTableFilter>) => {
      state.tableFilter = action.payload;
    },
    toggleTableFilter: (state, action: PayloadAction<boolean>) => {
      state.isTableFilterApplied = action.payload;
    },
    updateInitialValues: (
      state,
      action: PayloadAction<{
        filterMap: IAnnualReportsInit['filterMaps'];
        filterDataSet: IAnnualReportsInit['filterDataSet'];
        paygroupToYearMap: IAnnualReportsInit['paygroupToYearMap'];
      }>
    ) => {
      state.filterMaps = action.payload.filterMap;
      state.filterDataSet = action.payload.filterDataSet;
      state.paygroupToYearMap = action.payload.paygroupToYearMap;
    },
  },
});

export const {
  updateFilters,
  updateFilterMap,
  updateDefaultFilters,
  updateInitialValues,
  updateToInitialState,
  updateModalState,
  updateTableFilter,
  toggleTableFilter,
} = annualReportSlice.actions;
export const annualReportsReducer = annualReportSlice.reducer;
const selectAnnualReportStates = (state: RootState) => state.annualReportsReducer;
// Memoized selector using `createSelector`, without transforming output
export const selectAnnualReportFilters = createSelector(
  selectAnnualReportStates,
  (state) => state.filters // Returning the filters directly without transformation
);
export const selectDefaultFilters = createSelector(selectAnnualReportStates, (state) => state.defaultFilters);
export const selectAnnualReportFiltersMap = createSelector(
  selectAnnualReportStates,
  (state) => state.filterMaps // Returning the normalized filters
);
export const selectFilterDropdownOptions = createSelector(
  selectAnnualReportStates,
  (state) => state.filterDataSet // Returning the normalized filters
);
export const selectModalStatusGenerateReport = createSelector(
  selectAnnualReportStates,
  (state) => state.modalStates.generateReportVisible // Returning the normalized filters
);

export const selectPaygroupToYearMap = createSelector(
  selectAnnualReportStates,
  (state) => state.paygroupToYearMap // Returning the normalized filters
);

export const selectTableFilter = createSelector(
  selectAnnualReportStates,
  (state) => state.tableFilter // Returning the normalized filters
);

export const selectTableFilterApplied = createSelector(
  selectAnnualReportStates,
  (state) => state.isTableFilterApplied // Returning the normalized filters
);
