/**
 * Once design is confirmed, this needs to be moved into shared library
 */
import { useEnableTimeoutModal } from 'applications/app/timeoutPopover';
import { publish } from 'util/customEvents';
import { SHOW_SLOW_LOADING_POPUP } from 'util/customEvents/eventContracts';

const thresholdForTimeout = 8000; // 8 seconds is kept as the timeout threshold.

export const useTimeoutAction = () => {
  const { enableTimeoutModal } = useEnableTimeoutModal();
  const timeoutWrapper = async <R, T>(apiCall: () => Promise<T>): Promise<R> => {
    let timedOut = false;
    let timeoutId = 0;
    // Set a timeout for 8 seconds to show the reload popup
    if (enableTimeoutModal) {
      timeoutId = window.setTimeout(() => {
        timedOut = true;
        // this is a custom event which is triggred to show popup
        publish({ eventName: SHOW_SLOW_LOADING_POPUP, data: null });
      }, thresholdForTimeout);
    }
    try {
      const response = await apiCall();
      if (!timedOut) {
        clearTimeout(timeoutId);
      }
      return response as unknown as R;
    } catch (error) {
      clearTimeout(timeoutId);
      throw error;
    }
  };

  return { timeoutWrapper };
};
