import { Post } from 'services/httpClient';
import {
  IGetPayGroupListResponse,
  IGetPayGroupListRequest,
  IPersistPayGroupRequest,
  IPersistPayGroupResponse,
  IGetPayGroupRequest,
  IGetPayGroupResponse,
  IDisablePayGroupRequest,
  IManageAutoRunRequest,
  IManageAutoRunResponse,
  IDisablePayGroupResponse,
  IEnablePayGroupRequest,
  IEnablePayGroupResponse,
  IGetCalendarRequestInterface,
  IGetCalendarResponseInterface,
  IGetAutoRunSupportingDataRequest,
  IGetAutoRunSupportingDataResponse,
  IGetEmployeeListRequest,
  IGetEmployeeListResponse,
  DeletePaygroupRequest,
  DeletePaygroupResponse,
  IGetPayPeriodListRequest,
  IGetPayPeriodListResponse,
  ValidateCompanyFirstPayrollRequest,
  ValidateCompanyFirstPayrollResponse,
  IChangePersonPayGroupRequest,
  IChangePersonPayGroupResponse,
  IGetPayGroupTransferDetailRequest,
  IGetPayGroupTransferDetailResponse,
  IGetAutoRunStatusRequest,
  IGetAutoRunStatusResponse,
  GetPayGroupLiteListRequestInterface,
  GetPayGroupLiteListResponseInterface,
  IGetCalenderEventCountResponse,
  IGetCalenderEventCountRequest,
  PersistPayGroupFromAlfredRequestInterface,
  PersistPayGroupFromAlfredResponseInterface,
} from './paygroupAccess.contracts';

const servicedomain = '/payrollpaygroupaccess/';
const GetPayGroupListUrl = `${servicedomain}getpaygrouplist`;
const PersistPayGroupUrl = `${servicedomain}persistpaygroup`;
const PersistPayGroupFromAlfredUrl = `${servicedomain}persistPayGroupFromAlfred`;
const GetPayGroupUrl = `${servicedomain}getpaygroup`;
const DisablePayGroupUrl = `${servicedomain}disablepaygroup`;
const EnablePayGroupUrl = `${servicedomain}enablepaygroup`;
const ManageAutoRunUrl = `${servicedomain}manageautorun`;
const AutoRunSupportingDataUrl = `${servicedomain}getautorunsupportingdata`;
const GetCalendarUrl = `${servicedomain}getCalendar`;
const GetEmployeeListUrl = `${servicedomain}getemployeelist`;
const DeletePaygroupUrl = servicedomain + 'DeletePayGroup';
const GetPayPeriodListUrl = `${servicedomain}GetPayPeriodList`;
const GetPayGroupTransferDetailUrl = `${servicedomain}GetPayGroupTransferDetail`;
const ChangePersonPayGroupUrl = `${servicedomain}ChangePersonPayGroup`;
const ValidateCompanyFirstPayrollUrl = `${servicedomain}ValidateCompanyFirstPayroll`;
const GetAutoRunStatusUrl = `${servicedomain}GetAutoRunStatus`;
const GetPayGroupLiteListUrl = `${servicedomain}getPayGroupLiteList`;
const CalculateEventUrl = `${servicedomain}CalculatePaygroupCalendarEventCount`;

export const GetPayGroupList = (request: IGetPayGroupListRequest): Promise<IGetPayGroupListResponse> => {
  return Post(GetPayGroupListUrl, request);
};

export const GetPayGroup = (request: IGetPayGroupRequest): Promise<IGetPayGroupResponse> => {
  return Post(GetPayGroupUrl, request);
};

export const PersistPayGroup = (request: IPersistPayGroupRequest): Promise<IPersistPayGroupResponse> => {
  return Post(PersistPayGroupUrl, request);
};

export const persistPayGroupFromAlfred = (
  request: PersistPayGroupFromAlfredRequestInterface
): Promise<PersistPayGroupFromAlfredResponseInterface> => {
  return Post(PersistPayGroupFromAlfredUrl, request);
};

export const DisablePayGroup = (request: IDisablePayGroupRequest): Promise<IDisablePayGroupResponse> => {
  return Post(DisablePayGroupUrl, request);
};

export const EnablePayGroup = (request: IEnablePayGroupRequest): Promise<IEnablePayGroupResponse> => {
  return Post(EnablePayGroupUrl, request);
};

export const ManageAutoRun = (request: IManageAutoRunRequest): Promise<IManageAutoRunResponse> => {
  return Post(ManageAutoRunUrl, request);
};

export const AutoRunSupportingData = (
  request: IGetAutoRunSupportingDataRequest
): Promise<IGetAutoRunSupportingDataResponse> => {
  return Post(AutoRunSupportingDataUrl, request);
};

export const GetCalendar = (request: IGetCalendarRequestInterface): Promise<IGetCalendarResponseInterface> => {
  return Post(GetCalendarUrl, request);
};

export const GetEmployeeList = (request: IGetEmployeeListRequest): Promise<IGetEmployeeListResponse> => {
  return Post(GetEmployeeListUrl, request);
};

export const DeletePaygroup = (request: DeletePaygroupRequest): Promise<DeletePaygroupResponse> => {
  return Post(DeletePaygroupUrl, request);
};

export const GetPayPeriodList = (request: IGetPayPeriodListRequest): Promise<IGetPayPeriodListResponse> => {
  return Post(GetPayPeriodListUrl, request);
};

export const GetPayGroupTransferDetail = (
  request: IGetPayGroupTransferDetailRequest
): Promise<IGetPayGroupTransferDetailResponse> => {
  return Post(GetPayGroupTransferDetailUrl, request);
};

export const ChangePersonPayGroup = (request: IChangePersonPayGroupRequest): Promise<IChangePersonPayGroupResponse> => {
  return Post(ChangePersonPayGroupUrl, request);
};

export const ValidateCompanyFirstPayroll = (
  request: ValidateCompanyFirstPayrollRequest
): Promise<ValidateCompanyFirstPayrollResponse> => {
  return Post(ValidateCompanyFirstPayrollUrl, request);
};

export const GetAutoRunStatus = (request: IGetAutoRunStatusRequest): Promise<IGetAutoRunStatusResponse> => {
  return Post(GetAutoRunStatusUrl, request);
};

export const GetPayGroupLiteList = (
  request: GetPayGroupLiteListRequestInterface
): Promise<GetPayGroupLiteListResponseInterface> => {
  return Post(GetPayGroupLiteListUrl, request);
};

export const CalculatePaygroupCalendarEventCount = (
  request: IGetCalenderEventCountRequest
): Promise<IGetCalenderEventCountResponse> => {
  return Post(CalculateEventUrl, request);
};
