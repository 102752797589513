import { useFeatureFlag } from 'LDfeatureFlag';
import { FEATURE_FLAG_KEYS } from 'LDfeatureFlag/constants';
import { useEffect } from 'react';
import { subscribe, unsubscribe } from 'util/customEvents';
import { SHOW_SLOW_LOADING_POPUP } from 'util/customEvents/eventContracts';

export const useEnableTimeoutModal = () => {
  const { feature } = useFeatureFlag();
  const enableTimeoutModal = feature(FEATURE_FLAG_KEYS.EnableSeriveTimeoutModal);
  return { enableTimeoutModal };
};
export const useTimeoutActionModal = () => {
  const { enableTimeoutModal } = useEnableTimeoutModal();

  const toggleModal = (status: boolean) => {
    console.log('status', status); // actions TBD as per design.
    //please ignore this console on review:
  };

  useEffect(() => {
    if (enableTimeoutModal) {
      subscribe({ eventName: SHOW_SLOW_LOADING_POPUP, listener: toggleModal });
      return () => unsubscribe({ eventName: SHOW_SLOW_LOADING_POPUP, listener: toggleModal });
    }
  }, []);
};
