/* i18n defaults**/

export type SupportedLanguageCode = 'en-CA' | 'fr-FR' | 'en-UD';

type SupportedLanguage = {
  code: SupportedLanguageCode;
  name: string;
};

export const defaultLanguage: SupportedLanguageCode = 'en-CA';

export const supportedLanguages: SupportedLanguage[] = [
  { code: 'en-CA', name: 'English' },
  { code: 'fr-FR', name: 'French' },
  { code: 'en-UD', name: 'English-UD' },
];
