import { IWpNumberField } from '@wagepoint/ui-toolkit/components/wp-numberField';
import { BoxError } from 'redux/slice/yearEndSubmitFormStateSlice';

export const t4ExtraFieldsInRow = 3;
export const t4aExtraFieldsInRow = 3;
export const rl1ExtraFieldsInRow = 4;

export const caseOOptions = [
  { key: 'ca', value: 'CA' },
  { key: 'cb', value: 'CB' },
  { key: 'cc', value: 'CC' },
  { key: 'cd', value: 'CD' },
  { key: 'ra', value: 'RA' },
  { key: 'rb', value: 'RB' },
  { key: 'rc', value: 'RC' },
  { key: 'rd', value: 'RD' },
  { key: 'rg', value: 'RG' },
  { key: 'rh', value: 'RH' },
  { key: 'ri', value: 'RI' },
  { key: 'rj', value: 'RJ' },
  { key: 'rk', value: 'RK' },
  { key: 'rl', value: 'RL' },
  { key: 'rm', value: 'RM' },
  { key: 'rn', value: 'RN' },
  { key: 'ro', value: 'RO' },
  { key: 'rp', value: 'RP' },
  { key: 'rq', value: 'RQ' },
  { key: 'rr', value: 'RR' },
  { key: 'rs', value: 'RS' },
  { key: 'rt', value: 'RT' },
  { key: 'ru', value: 'RU' },
  { key: 'rv', value: 'RV' },
  { key: 'rx', value: 'RX' },
];

export const employmentCodesOptions = [
  { key: '11', value: '11' },
  { key: '12', value: '12' },
  { key: '13', value: '13' },
  { key: '14', value: '14' },
  { key: '15', value: '15' },
  { key: '16', value: '16' },
  { key: '17', value: '17' },
];

export const t4EmployeeDentalBenefitsOptions = [
  { key: '1', value: 't4Form.2.boxLabel45option1' },
  { key: '2', value: 't4Form.2.boxLabel45option2' },
  { key: '3', value: 't4Form.2.boxLabel45option3' },
  { key: '4', value: 't4Form.2.boxLabel45option4' },
  { key: '5', value: 't4Form.2.boxLabel45option5' },
];

export const t4ExemptOptions = [
  { key: '1', value: 't4Form.2.boxLabel28a' },
  { key: '2', value: 't4Form.2.boxLabel28b' },
  { key: '3', value: 't4Form.2.boxLabel28c' },
];

export const t4MoneyFieldsWithLabelKeys = [
  { boxNumber: 14, name: 'box14', labelKey: 't4Form.2.boxLabel14', maxLength: 10 },
  { boxNumber: 22, name: 'box22', labelKey: 't4Form.2.boxLabel22', maxLength: 10 },
  { boxNumber: 16, name: 'box16', labelKey: 't4Form.2.boxLabel16', maxLength: 6 },
  { boxNumber: '16A', name: 'box16a', labelKey: 't4Form.2.boxLabel16a', maxLength: 6 },
  { boxNumber: 17, name: 'box17', labelKey: 't4Form.2.boxLabel17', maxLength: 6 },
  { boxNumber: '17A', name: 'box17a', labelKey: 't4Form.2.boxLabel17a', maxLength: 6 },
  { boxNumber: 18, name: 'box18', labelKey: 't4Form.2.boxLabel18', maxLength: 6 },
  { boxNumber: 20, name: 'box20', labelKey: 't4Form.2.boxLabel20', maxLength: 7 },
  { boxNumber: 24, name: 'box24', labelKey: 't4Form.2.boxLabel24', maxLength: 7 },
  { boxNumber: 26, name: 'box26', labelKey: 't4Form.2.boxLabel26', maxLength: 9 },
  { boxNumber: 44, name: 'box44', labelKey: 't4Form.2.boxLabel44', maxLength: 9 },
  { boxNumber: 45, name: 'box45', labelKey: 't4Form.2.boxLabel45', dropdownOptions: t4EmployeeDentalBenefitsOptions },
  { boxNumber: 46, name: 'box46', labelKey: 't4Form.2.boxLabel46', maxLength: 9 },
  {
    boxNumber: 50,
    name: 'box50',
    labelKey: 't4Form.2.boxLabel50',
    maxLength: 7,
    numberFieldConfig: {
      fieldType: 'number' as IWpNumberField['fieldType'],
      isControlled: false,
      customPrecision: 0,
    },
  },
  { boxNumber: 52, name: 'box52', labelKey: 't4Form.2.boxLabel52', maxLength: 7 },
  { boxNumber: 56, name: 'box56', labelKey: 't4Form.2.boxLabel56', maxLength: 7 },
  { boxNumber: 55, name: 'box55', labelKey: 't4Form.2.boxLabel55', maxLength: 6 },
];

export const t4AdditionalInfoConfig = {
  maxRowsCount: 4,
  maxAmountLength: 9,
};

export const t4aMoneyFieldsWithLabelKeys = [
  { boxNumber: 15, name: 'box015', labelKey: 't4AForm.1.boxLabel15', dropdownOptions: t4EmployeeDentalBenefitsOptions },
  { boxNumber: 16, name: 'box016', labelKey: 't4AForm.1.boxLabel16', maxLength: 11 },
  { boxNumber: 22, name: 'box022', labelKey: 't4AForm.1.boxLabel22', maxLength: 11 },
  { boxNumber: 18, name: 'box018', labelKey: 't4AForm.1.boxLabel18', maxLength: 11 },
  { boxNumber: 20, name: 'box020', labelKey: 't4AForm.1.boxLabel20', maxLength: 11 },
  { boxNumber: 24, name: 'box024', labelKey: 't4AForm.1.boxLabel24', maxLength: 11 },
  { boxNumber: 48, name: 'box048', labelKey: 't4AForm.1.boxLabel48', maxLength: 11 },
];

export const t4AAdditionalInfoConfig = {
  maxRowsCount: 4,
  maxAmountLength: 11,
};

export const rl1MoneyFieldsWithLabelKeys = [
  { name: 'A', labelKey: 'rl-1Form.2.label01' },
  { name: 'B', labelKey: 'rl-1Form.2.label02' },
  { name: 'C', labelKey: 'rl-1Form.2.label03' },
  { name: 'D', labelKey: 'rl-1Form.2.label04' },
  { name: 'E', labelKey: 'rl-1Form.2.label05' },
  { name: 'F', labelKey: 'rl-1Form.2.label06' },
  { name: 'G', labelKey: 'rl-1Form.2.label07' },
  { name: 'H', labelKey: 'rl-1Form.2.label08' },
  { name: 'I', labelKey: 'rl-1Form.2.label09' },
  { name: 'J', labelKey: 'rl-1Form.2.label10' },
  { name: 'K', labelKey: 'rl-1Form.2.label11' },
  { name: 'L', labelKey: 'rl-1Form.2.label12' },
  { name: 'M', labelKey: 'rl-1Form.2.label13' },
  { name: 'N', labelKey: 'rl-1Form.2.label14' },
  { name: 'O', labelKey: 'rl-1Form.2.label15' },
  { name: 'P', labelKey: 'rl-1Form.2.label16' },
  { name: 'Q', labelKey: 'rl-1Form.2.label17' },
  { name: 'R', labelKey: 'rl-1Form.2.label18' },
  { name: 'S', labelKey: 'rl-1Form.2.label19' },
  { name: 'T', labelKey: 'rl-1Form.2.label20' },
  { name: 'U', labelKey: 'rl-1Form.2.label21' },
  { name: 'V', labelKey: 'rl-1Form.2.label22' },
  { name: 'W', labelKey: 'rl-1Form.2.label23' },
];

export const rl1SummaryMoneyFieldNames = [
  'total26',
  'total27',
  '29',
  '311',
  '38',
  '39',
  '50',
  '51',
  '52',
  '53',
  '70',
  '71',
  '72',
];
export const t4DisabledFields = ['box16a', 'box17a'];
export const t4DisabledFieldsNew = ['box17a'];

export const t4NotMoneyFields = ['box45', 'box50'];

export const t4ANotMoneyFields = ['box015'];

export const t4GridUselessFields = ['box10', 'box28a', 'box28b', 'box28c', 'firstName', 'lastName', 'rl1-Name'];

export const t4GridNotMoneyFields = ['box29'];

export const t4aGridUselessFields = ['lastName', 'firstName'];

export const rl1GridUselessFields = ['firstName', 'lastName', 'rl1-Name'];

export const rl1GridNotMoneyFields = ['Ocode'];

export const gridNotMoneyFields = [...t4GridNotMoneyFields, ...rl1GridNotMoneyFields];

export const rl1SummarySendFieldNames: Record<string, string> = {
  areaCode: 'Area Code',
  onPaper: 'on paper',
};

export const t4MoneyFieldNames = t4MoneyFieldsWithLabelKeys
  .map(({ name }) => name)
  .filter((name) => !t4NotMoneyFields.includes(name));

export const t4aMoneyFieldNames = t4aMoneyFieldsWithLabelKeys
  .map(({ name }) => name)
  .filter((name) => !t4ANotMoneyFields.includes(name));

export const rl1MoneyFieldNames = rl1MoneyFieldsWithLabelKeys.map(({ name }) => name);

export const defaultT4FormBannerErrors: BoxError[] = [
  {
    boxName: 'box45',
    errorMessage: 't4Form.1.validationBannerText',
    bannerPriority: 'lowpriority',
  },
];

export const defaultT4AFormBannerErrors: BoxError[] = [
  {
    boxName: 'box15',
    errorMessage: 't4AForm.0.validationBannerText',
    bannerPriority: 'lowpriority',
  },
];
