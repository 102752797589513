import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
// TODO: replace Typography with WpTypography from UI Toolkit.
// eslint-disable-next-line no-restricted-imports
import Typography from '@material-ui/core/Typography';
import { FormProvider, useForm } from 'react-hook-form';
import WpCheckRadio from '@wagepoint/ui-toolkit/components/wp-radioCheck/wpCheckRadio';
import { RadioCheckTypeEnum } from '@wagepoint/ui-toolkit/components/wp-radioCheck-new';
import WpDropdown from '@wagepoint/ui-toolkit/components/wp-dropdown/wpDropdown';
import WpButton from '@wagepoint/ui-toolkit/components/wp-button/wpButton';
import WpBottomNav from '@wagepoint/ui-toolkit/components/wp-bottomNav/wpBottomNav';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import {
  IGetAdministratorListRequest,
  IUser,
  ITransferOwnershipRequest,
  ITransferOwnershipResponse,
} from 'services/security-access/securityAccess.contracts';
import { GetAdminList, TransferOwnershipService } from 'services/security-access/securityAccess.service';
import { GetLocationList, GetDepartmentList } from 'services/company-access/companyAccess.service';
import {
  GetLocationListRequest,
  GetLocationListResponse,
  ILocation,
} from 'services/company-access/companyAccess.contracts';
import { SecurityRoleTypeEnum } from 'services/shared/securityRoleTypeEnum';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { clearSentryUserContext, getLogoutUrl } from 'util/utility';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from 'redux/hooks/hook';
import { selectCompanyId } from 'redux/slice/configSlice';
import { error } from 'redux/slice/snackbarSlice';
import { hideLoading, showLoading } from 'redux/slice/loadingIndicatorSlice';
import { useHistory } from 'react-router-dom';
import { roleList, displayRoleList } from '../common';
import { useViewPermissionByPartPath } from 'util/customHooks/useViewPermission';
import { setTransferState } from 'redux/slice/userAccountsSlice';
import WpStatements from '@wagepoint/ui-toolkit/components/wp-statements/wpStatements';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropdown: {
      // width: '348px',
    },
    subheader: {
      paddingLeft: '0px !important',
      paddingTop: '0px !important',
    },
    secondaryFooterContainer: {
      paddingLeft: theme.spacing(3),
    },
    primaryFooterContainer: {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
  })
);

export enum TransferType {
  Disable = 1,
  Assign = 2,
}

export enum Departments {
  NoDepartment = 1,
  MaximumManagers = 2,
}

const TransferOwnership: React.FC<{ dontShowTabs?: boolean }> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation(['userAccounts']);
  const dispatch = useAppDispatch();
  const companyId = useAppSelector(selectCompanyId);
  const history = useHistory();
  const { isViewOnlyPermission } = useViewPermissionByPartPath();

  const [transferType, setTransferType] = useState();
  const [adminList, setAdminList] = useState<Array<IUser>>([]);
  const [selectedAdmin, setSelectedAdmin] = useState<number>(0);
  const [accountOwnerId, setAccountOwnerId] = useState<number>(0);
  const [logoutUrl, setLogoutUrl] = React.useState<string>('');
  const [locationList, setLocationList] = useState<Array<ILocation>>([]);
  const [departmentList, setDepartmentList] = useState<Array<any>>([]);
  const [locationListForService, setLocationListForService] = useState<Array<any>>([]);
  const [departmentListForService, setDepartmentListForService] = useState<Array<any>>([]);
  const [role, setRole] = useState<any>();
  const [roleListToDisplay, setRoleListToDisplay] = useState(roleList(t));

  const formValidationSchema = yup.object().shape({
    radioGroup: yup.string().required(t('transferOwnership.validation')).nullable(),
    admin: yup.string().required(t('transferOwnership.admin')).nullable(),
    department: yup.array().min(1, t('transferOwnership.departmentValidation')),
    ...(transferType == TransferType.Assign
      ? {
          rolesLabel: yup.string().required(t('transferOwnership.validation')).nullable(),
        }
      : null),
  });

  const methods = useForm({ resolver: yupResolver(formValidationSchema) });

  const { errors, trigger, handleSubmit, setValue } = methods;

  useEffect(() => {
    getAdminList();
    getLocationList();
    getDeparmentList();
  }, []);

  useEffect(() => {
    setLogoutUrl(getLogoutUrl());
  }, []);

  const getDeparmentList = async () => {
    const request: any = {
      companyId,
    };
    const response: any = await GetDepartmentList(request);
    if (response.validationResults && response?.validationResults?.validationResultList.length == 0) {
      if (response?.departmentList.length == 0) {
        setRoleListToDisplay(
          roleListToDisplay.map((item) =>
            item.id == SecurityRoleTypeEnum.Manager
              ? { ...item, disable: true, hoverContent: t('transferOwnership.noManager') }
              : item
          )
        );
      } else {
        const departmentList = response?.departmentList.filter((item: any) => !item.hasManager);
        if (departmentList.length == 0) {
          setRoleListToDisplay(
            roleListToDisplay.map((item) =>
              item.id == SecurityRoleTypeEnum.Manager
                ? { ...item, disable: true, hoverContent: t('transferOwnership.exceedManager') }
                : item
            )
          );
        }
        setDepartmentList(departmentList);
      }
    } else {
      setDepartmentList([]);
    }
  };

  const getLocationList = async () => {
    const request: GetLocationListRequest = {
      skip: 0,
      take: 50,
      getByIdList: false,
      companyId,
    };
    const response: GetLocationListResponse = await GetLocationList(request);
    if (response.validationResults && response?.validationResults?.validationResultList.length == 0) {
      const ids = response?.locationList.map((loc: any) => loc.stateId);
      const filtered = response?.locationList.filter(({ stateId }, index) => !ids.includes(stateId, index + 1));
      setLocationList(filtered);
    } else {
      setLocationList([]);
    }
  };

  const onSubmit = (data: any) => {
    trigger();
    transferOwnershipService(data);
  };

  const transferOwnershipService = async (data: any) => {
    dispatch(showLoading());

    const request: ITransferOwnershipRequest = {
      userId: accountOwnerId,
      newOwnerUserId: selectedAdmin,
      disable: data?.radioGroup == TransferType.Disable ? true : false,
      securityRoleType: role,
      departmentIdList: departmentListForService,
      stateIdList: locationListForService,
    };

    const response: ITransferOwnershipResponse = await TransferOwnershipService(request);
    if (response.validationResults && response.validationResults?.validationResultList?.length === 0) {
      clearSentryUserContext();
      window.location.href = logoutUrl;
      clearSentryUserContext();
    } else {
      dispatch(hideLoading());
      dispatch(
        error({
          message: response.validationResults?.validationResultList[0].validationMessage || 'Some Error Occurred',
        })
      );
    }
  };

  const getAdminList = async () => {
    const request: IGetAdministratorListRequest = {
      companyId,
      includeAccountingAdmin: true,
    };

    const response: any = await GetAdminList(request);
    if (response.validationResults && response.validationResults?.validationResultList?.length === 0) {
      const formattedList: Array<IUser> = response.userList.map((users: any) => {
        users.securityRoleTypeList.map((roles: any) => {
          users.fullName = Object.values(SecurityRoleTypeEnum).includes(roles) ? (
            <>
              {`${users.firstName}\u00A0${users.lastName}\u00A0(${
                displayRoleList(t).find((x) => x.id === roles)?.name
              })`}
              {roles === SecurityRoleTypeEnum.AccountOwner ? setAccountOwnerId(users.id) : null}
            </>
          ) : (
            `${users.firstName}\u00A0${users.lastName}`
          );
        });

        return users;
      });
      setAdminList(formattedList);
    } else {
      setAdminList([]);
    }
  };

  const onTransferOwnershipChange = (e: any) => {
    setTransferType(e?.target.value);
  };

  const handleSelectedEmployee = (e: any) => {
    setSelectedAdmin(e);
  };

  const handleLocationSelection = (e: any) => {
    setLocationListForService(e);
  };

  const handleDepartmentSelection = (e: any) => {
    const filteredList = departmentList.filter((dep?: any) => e.includes(dep.id)).map((item: any) => item.name);
    setValue('department', filteredList);
    trigger('department');
    setDepartmentListForService(e);
  };

  const handleBack = () => {
    history.goBack();
    dispatch(
      setTransferState({
        isFromTransfer: true,
      })
    );
  };

  return (
    <>
      <Typography variant="h3" className={classes.subheader}>
        {t('transferOwnership.newAccountOwner')}
      </Typography>

      <FormProvider {...methods}>
        <form>
          <Grid container>
            <Grid item xs={4}>
              <Box component="div" className={classes.dropdown}>
                <WpDropdown
                  id="key"
                  label={t('transferOwnership.selectAnAdmin')}
                  name="admin"
                  menuItems={adminList.filter(
                    (item: any) => !item?.securityRoleTypeList.includes(SecurityRoleTypeEnum.AccountOwner)
                  )}
                  displayKey="id"
                  displayValue="fullName"
                  returnValue="id"
                  className={classes.dropdown}
                  callBackOnChange={handleSelectedEmployee}
                  errorobj={errors}
                  required={true}
                  disabled={isViewOnlyPermission}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2"> {t('transferOwnership.howToTransfer')} </Typography>
              <WpCheckRadio
                disabled={isViewOnlyPermission}
                elementType={RadioCheckTypeEnum.radioButton}
                onChange={onTransferOwnershipChange}
                defaultValue={transferType}
                dataList={[
                  {
                    value: TransferType.Disable,
                    label: t('transferOwnership.transferAndDisable'),
                  },
                  {
                    value: TransferType.Assign,
                    label: t('transferOwnership.transferAndAssign'),
                  },
                ]}
                errorobj={errors}
                name="radioGroup"
              />

              {transferType == TransferType.Disable && (
                <WpStatements variant="highpriority" message={t('transferOwnership.disableStatement')} />
              )}

              {transferType == TransferType.Assign && (
                <>
                  <Grid item xs={4}>
                    <Box component="div" className={classes.dropdown}>
                      <WpDropdown
                        id="key"
                        label={t('editView.roleLabel')}
                        name="rolesLabel"
                        menuItems={roleListToDisplay}
                        displayKey="id"
                        displayValue="name"
                        returnValue="id"
                        errorobj={errors}
                        required={true}
                        className={classes.dropdown}
                        callBackOnChange={(selected) => {
                          setRole(selected);
                        }}
                      />
                    </Box>
                  </Grid>
                  <WpStatements variant="highpriority" message={t('transferOwnership.enableStatement')} />

                  {role === SecurityRoleTypeEnum.Manager && (
                    <>
                      <Grid item xs={4}>
                        <Box component="div" className={classes.dropdown}>
                          <WpDropdown
                            id="stateId"
                            label={t('assignRole.locationLabel')}
                            name="location"
                            menuItems={locationList}
                            multiple
                            hasSelectAll={true}
                            displayKey="stateId"
                            displayValue="state"
                            returnValue="stateId"
                            callBackOnChange={handleLocationSelection}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box component="div" className={classes.dropdown}>
                          <WpDropdown
                            id="department"
                            label={t('assignedPeopleGroup.columns.assignDepartment')}
                            name="department"
                            menuItems={departmentList}
                            multiple
                            hasSelectAll={true}
                            displayKey="id"
                            displayValue="name"
                            returnValue="id"
                            className={classes.dropdown}
                            errorobj={errors}
                            required={true}
                            callBackOnChange={handleDepartmentSelection}
                          />
                        </Box>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      {!props?.dontShowTabs ? (
        <WpButton variant="contained" color="primary" onClick={handleSubmit(onSubmit)} disabled={isViewOnlyPermission}>
          {t('roleTabLinks.transferOwnership')}
        </WpButton>
      ) : (
        <>
          <WpBottomNav
            primaryBtn={{
              text: t('roleTabLinks.transferOwnership'),
              fn: handleSubmit(onSubmit),
            }}
            secondaryBtn={{
              text: t('userAccounts:permissionSettings.back'),
              fn: handleBack,
            }}
          />
        </>
      )}
    </>
  );
};

export default TransferOwnership;
