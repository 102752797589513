import { Post } from '../httpClient';
import {
  IGetYearEndPackageRequest,
  IGetYearEndPackageResponse,
  IGetYearEndPackageListResponse,
  IPrepareYearEndFormsResponse,
  IMaintainPreferenceParams,
  IMaintainPreferenceResponse,
  ITaxFormResponse,
  YearEndFormRequest,
  SummaryFormRequest,
  IGetAmendmentTaxFormsRequest,
  IGetAmendmentTaxFormsResponse,
  IGetEarningDiscrepancyPdfResponse,
  IGetEmployeeTaxFormsListRequestInterface,
  IGetEmployeeTaxFormsListResponse,
  IGetChecklistResponseInterface,
  IGetTaxAccountNumbersRequest,
  IGetTaxAccountNumbersResponse,
  IGetTaxYearsRequestInterface,
  IGetTaxYearsResponse,
  IPersistSummaryAdditionalBoxRequest,
  IPersistAmendmentPackageRequest,
  IPersistAmendmentPackageResponse,
  IPersistChecklistRequestInterface,
  IPersistChecklistResponseInterface,
  IPersistPackageRequest,
  IPersistPackageResponse,
  IGetInitialStateRequestInterface,
  IGetInitialStateResponseInterface,
  IGetSummaryFormRequest,
  IGetSummaryFormResponse,
  IGetSummaryListRequest,
  IGetSummaryListResponse,
  IMaintainFormMatrixRequest,
  IGetOtherInformationFormBoxesRequest,
  IGetOtherInformationFormBoxesResponse,
  IPrepareTaxSummaryRequest,
  IValidationResultsResponse,
  IMaintainFormMatrixResponse,
  DownloadCSVRequest,
  DownloadCSVResponse,
  DownloadPackageRequest,
  DownloadPackageResponse,
  ResponseValidationResults,
  YearEndDefaultParams,
  YearEndRequest,
  IGetContractorListResponse,
  IGetContractorListRequest,
} from './payrollYearEndAccess.contracts';
import { GetDocumentAsBlob } from '@wagepoint/ui-toolkit/components/wp-download/wpDownloadService';

const servicedomain = '/payrollyearendaccess/';
const GetAmendmentTaxFormsURL = `${servicedomain}GetAmendmentTaxForms`;
const GetYearEndPackageURL = `${servicedomain}GetYearEndPackage`;
const GetYearEndPackageListURL = `${servicedomain}GetYearEndPackageList`;
const MaintainPreferenceURL = `${servicedomain}MaintainPreference`;
const PrepareYearEndFormsURL = `${servicedomain}PrepareYearEndForms`;
const GetCheckListURL = `${servicedomain}GetCheckList`;
const GetEmployeeTaxFormsURL = `${servicedomain}GetEmployeeTaxForms`;
const GetTaxYearsURL = `${servicedomain}GetTaxYears`;
const GetTaxAccountNumbersURL = `${servicedomain}GetTaxAccountNumbers`;
const GetEarningDiscrepancyPdfURL = `${servicedomain}GetEarningDiscrepancyPdf`;
const PersistAmendmentPackageURL = `${servicedomain}PersistAmendmentPackage`;
const UpdateCheckListURL = `${servicedomain}PersistCheckList`;
const PersistPackageURL = `${servicedomain}PersistPackage`;
const GetInitialStateURL = `${servicedomain}GetInitialState`;
const MaintainFormURL = `${servicedomain}MaintainForm`;
const MaintainSummaryFormURL = `${servicedomain}MaintainSummaryForm`;
const GetSummaryFormURL = `${servicedomain}GetSummaryForm`;
const GetSummaryListURL = `${servicedomain}GetSummaryList`;
const MaintainFormMatrixURL = `${servicedomain}MaintainFormMatrix`;
const GetOtherInformationFormBoxesURL = `${servicedomain}GetOtherInformationFormBoxes`;
const PrepareTaxSummaryURL = `${servicedomain}PrepareTaxSummary`;
const PersistRL1SummaryURL = `${servicedomain}PersistRL1Summary`;
const PersistSummaryAdditionalBoxURL = `${servicedomain}persistSummaryAdditionalBox`;
const RevertToOriginalURL = `${servicedomain}RevertToOriginal`;
const DownloadCSVURL = `${servicedomain}DownloadCSV`;
const DownloadPackageURL = `${servicedomain}DownloadPackage`;
const GetContractorListURL = `${servicedomain}GetContractorList`;

export const GetAmendmentTaxForms = (request: IGetAmendmentTaxFormsRequest): Promise<IGetAmendmentTaxFormsResponse> => {
  return Post(GetAmendmentTaxFormsURL, request);
};

export const GetEarningDiscrepancyPdf = (request: YearEndDefaultParams): Promise<IGetEarningDiscrepancyPdfResponse> => {
  return Post(GetEarningDiscrepancyPdfURL, request);
};

export const viewEarningDiscrepancyPdf = async (request: YearEndDefaultParams, name: string) => {
  const response = await GetEarningDiscrepancyPdf(request);
  if (response.pdfContent) GetDocumentAsBlob(name, response.pdfContent, 'application/pdf');
};

export const GetYearEndPackage = (request: IGetYearEndPackageRequest): Promise<IGetYearEndPackageResponse> => {
  return Post(GetYearEndPackageURL, request);
};

export const GetYearEndPackageList = (request: YearEndDefaultParams): Promise<IGetYearEndPackageListResponse> => {
  return Post(GetYearEndPackageListURL, request);
};

export const MaintainPreference = (request: IMaintainPreferenceParams): Promise<IMaintainPreferenceResponse> => {
  return Post(MaintainPreferenceURL, request);
};

export const MaintainForm = (request: YearEndFormRequest): Promise<ITaxFormResponse> => {
  return Post(MaintainFormURL, request);
};

export const MaintainSummaryForm = (request: SummaryFormRequest): Promise<ITaxFormResponse> => {
  return Post(MaintainSummaryFormURL, request);
};

export const PrepareYearEndForms = (request: YearEndDefaultParams): Promise<IPrepareYearEndFormsResponse> => {
  return Post(PrepareYearEndFormsURL, request);
};

export const GetCheckList = (request: YearEndDefaultParams): Promise<IGetChecklistResponseInterface> => {
  return Post(GetCheckListURL, request);
};

export const GetEmployeeTaxFormsList = (
  request: IGetEmployeeTaxFormsListRequestInterface
): Promise<IGetEmployeeTaxFormsListResponse> => {
  return Post(GetEmployeeTaxFormsURL, request);
};

export const GetInitialState = (
  request: IGetInitialStateRequestInterface
): Promise<IGetInitialStateResponseInterface> => {
  return Post(GetInitialStateURL, request);
};

export const GetTaxAccountNumbers = (request: IGetTaxAccountNumbersRequest): Promise<IGetTaxAccountNumbersResponse> => {
  return Post(GetTaxAccountNumbersURL, request);
};

export const GetTaxYears = (request: IGetTaxYearsRequestInterface): Promise<IGetTaxYearsResponse> => {
  return Post(GetTaxYearsURL, request);
};

export const PersistSummaryAdditionalBox = (
  request: IPersistSummaryAdditionalBoxRequest
): Promise<ResponseValidationResults> => {
  return Post(PersistSummaryAdditionalBoxURL, request);
};

export const PersistAmendmentPackage = (
  request: IPersistAmendmentPackageRequest
): Promise<IPersistAmendmentPackageResponse> => {
  return Post(PersistAmendmentPackageURL, request);
};

export const PersistPackage = (request: IPersistPackageRequest): Promise<IPersistPackageResponse> => {
  return Post(PersistPackageURL, request);
};

export const UpdateCheckList = (
  request: IPersistChecklistRequestInterface
): Promise<IPersistChecklistResponseInterface> => {
  return Post(UpdateCheckListURL, request);
};

export const GetSummaryForm = (request: IGetSummaryFormRequest): Promise<IGetSummaryFormResponse> => {
  return Post(GetSummaryFormURL, request);
};

export const GetSummaryList = (request: IGetSummaryListRequest): Promise<IGetSummaryListResponse> => {
  return Post(GetSummaryListURL, request);
};

export const MaintainFormMatrix = (request: IMaintainFormMatrixRequest): Promise<IMaintainFormMatrixResponse> => {
  return Post(MaintainFormMatrixURL, request);
};

export const GetOtherInformationFormBoxes = (
  request: IGetOtherInformationFormBoxesRequest
): Promise<IGetOtherInformationFormBoxesResponse> => {
  return Post(GetOtherInformationFormBoxesURL, request);
};

export const PrepareTaxSummary = (request: IPrepareTaxSummaryRequest): Promise<IValidationResultsResponse> => {
  return Post(PrepareTaxSummaryURL, request);
};

export const PersistRL1Summary = (request: YearEndFormRequest): Promise<IValidationResultsResponse> => {
  return Post(PersistRL1SummaryURL, request);
};

export const RevertToOriginal = (request: { yearEndTaxFormId: number }): Promise<ITaxFormResponse> => {
  return Post(RevertToOriginalURL, request);
};

export const DownloadCSV = (request: DownloadCSVRequest): Promise<DownloadCSVResponse> => {
  return Post(DownloadCSVURL, request);
};

export const DownloadPackage = (request: DownloadPackageRequest): Promise<DownloadPackageResponse> => {
  return Post(DownloadPackageURL, request);
};

export const PrepareYearEndFormsNew = (request: YearEndRequest): Promise<IPrepareYearEndFormsResponse> => {
  return Post(PrepareYearEndFormsURL, request);
};

export const GetContractorList = (request: IGetContractorListRequest): Promise<IGetContractorListResponse> => {
  return Post(GetContractorListURL, request);
};
