import { IValidationResults } from 'services/shared/validationResults.contracts';
import { ValidationScopeTypeEnum } from '../priorpayroll-access/priorpayrollAccess.contracts';
import { AutoRunDisableToolTipEnum, ValidationResultsInterface } from 'services/payroll/models/access/payGroupAccess';

export interface IPayGroupSummary {
  payGroupId: number;
  name: string;
  calendarId: number;
  companyId: number;
  payGroupStatusType: PayGroupStatusTypeEnum;
  payGroupFrequencyType: string;
  isAutoRun: boolean;
  canManageAutoRun?: boolean;
  canDisable?: boolean;
  autoRunDisableToolTipList?: Array<AutoRunDisableToolTipEnum>;
  autoRunModfiedDate?: Date;
  salariedEmployeeCount: number;
  hourlyEmployeeCount: number;
  salariedContractorCount: number;
  hourlyContractorCount: number;
  commisionContractorCount: number;
  commisionEmployeeCount: number;
  IsOverFlow?: boolean;
  hasRegularPayrollRun?: boolean;
  payGroupPayPeriodType?: SemiMonthlyPayPeriodTypeEnum | MonthlyPayPeriodTypeEnum;
}

export interface IGetPayGroupListResponse {
  totalCount: number;
  totalDisableCount: number;
  validationResults: any;
  payGroupSummaryList: Array<IPayGroupSummary>;
}

export interface IGetPayGroupListRequest {
  companyId: number;
  getByCompanyId: boolean;
  skip: number;
  take: number;
  getByPayGroupStatusType?: boolean;
  payGroupStatusType?: PayGroupStatusTypeEnum;
  getByIdList?: boolean;
  idList?: number[];
  includeIncompleteEmployee?: boolean;
}

export interface IPersistPayGroupRequest {
  payGroup: IPayGroup;
  validationScopeType?: ValidationScopeTypeEnum;
  isMigratedPaygroup?: boolean;
}
export interface IGetCalenderEventCountRequest {
  payGroup: IPayGroup;
  year: number;
}
export interface IGetCalenderEventCountResponse {
  calendarEventCount: number;
  validationResults: IValidationResults;
  payPeriodList: IPayPeriodInterface[];
}

export interface IPayGroup {
  id: number;
  name: string;
  lastSavedDate?: string;
  firstPayrollStartDate?: string;
  companyId: number | undefined;
  calendarId: number | null;
  payPeriodTemplateId: number;
  payGroupStatusType: PayGroupStatusTypeEnum;
  payrollFrequencyType: PayGroupFrequencyTypeEnum;
  isAutoRun: boolean | false;
  canEditFrequencySetting: boolean | false;
  createdDate: Date | null;
  calendarOwnerType: number | null;
  payGroupSetting: IPayGroupSetting | null;
  payGroupSettingId: number | null;
  payGroupView?: IPayGroupView | null;
}

export interface IPayGroupView {
  id: number | null;
  name: string | null;
  startDate: Date | null | string;
  endDate: Date | null | string;
  payDate: Date | null | string;
  firstPayDate: Date | null | string;
  secondEndDate: Date | null | string;
  frequencyId: number | null;
  frequencyName: string;
  periodNumber: number | null;
  payPeriodLength: number;
  payGroupSetting?: IPayGroupSetting | null;
  formattedDate: IFormattedDate | null;
  MonthlyEndDatePatternType?: number | undefined;
  PaydateWeekendAdjustmentType?: number | undefined;
  PaydateBankingHolidayAdjustmentType?: number | undefined;
  showSemiMonthlyPayDates?: any;
  showMonthlyPayDates?: any;
  semiMonthlyPayGroupPayPeriodType?: SemiMonthlyPayPeriodTypeEnum;
  monthlyPayGroupPayPeriodType?: MonthlyPayPeriodTypeEnum;
}

export interface IFormattedDate {
  startDate: string | null;
  endDate: string | null;
  payDate: string | null;
  firstPayDate: string | null;
  secondPayDate: string | null;
}

export interface IGetPayGroupRequest {
  payGroupId?: number;
  includePayPeriodList: boolean;
  payPeriodStartDate?: Date;
  payPeriodEndDate?: Date;
  includeSupportingData: boolean;
  companyId: number | undefined;
}

export interface IGetPayGroupResponse {
  validationResults: any;
  payGroup: IPayGroup;
  supportingData: ISupportingData;
}

export interface ISupportingData {
  payGroupFrequencyList: Array<IPayGroupFrequency>;
}

export interface IPayGroupFrequency {
  id: number;
  name: string;
  periodNumberList: Array<number>;
}

export interface IPersistPayGroupResponse {
  payGroupId: number;
  validationResults: IValidationResults;
}

export interface IPayGroupSetting {
  id: number;
  weeklyHour: number | string;
  hoursPerPayPeriod: number;
  startPayPeriodId: number;
  frequencySetting: IFrequencySetting;
  totalNumberOfPeriods: number;
}

export interface IFrequencySetting {
  weekly: IWeekly | null | undefined;
  biweekly: IBiWeekly | null | undefined;
  semiMonthly: ISemiMonthly | null | undefined;
  monthly: IMonthly | null | undefined;
  quarterly: IQuarterly | null | undefined;
}
export interface IWeekly {
  startDate: Date | null | string;
  payDate: Date | null | string;
  endDate: Date | null | string;
}
export interface IBiWeekly {
  startDate: Date | null | string;
  payDate: Date | null | string;
  endDate: Date | null | string;
}
export interface ISemiMonthly {
  firstEndDay: number | undefined | null;
  firstPayDay: number | undefined | null;
  firstPayDatePatternType?: MonthlyEndDatePatternTypeEnum;
  firstEndDatePatternType?: MonthlyEndDatePatternTypeEnum;
  secondEndDay: number | undefined | null;
  secondPayDay: number | undefined | null;
  secondPayDatePatternType?: MonthlyEndDatePatternTypeEnum;
  secondEndDatePatternType?: MonthlyEndDatePatternTypeEnum;
  paydateWeekendAdjustmentType: number | undefined | undefined;
  semiMonthlyPayGroupPayPeriodType: SemiMonthlyPayPeriodTypeEnum;
  firstPayDateFallsType?: PayDateFallsTypeEnum;
  secondPayDateFallsType?: PayDateFallsTypeEnum;
}

export enum PayDateFallsTypeEnum {
  notDefined = 0,
  beforeThePayPeriod = 1,
  withinThePayPeriod = 2,
  afterThePayPeriod = 3,
}

export interface IMonthly {
  payDay: number | null;
  endDay: number | null;
  isWithinCycle: boolean | null;
  paydateWeekendAdjustmentType: number | undefined;
  paydateBankingHolidayAdjustmentType: number | undefined;
  monthlyPayGroupPayPeriodType: MonthlyPayPeriodTypeEnum;
  endDatePatternType?: MonthlyEndDatePatternTypeEnum;
  payDatePatternType?: MonthlyEndDatePatternTypeEnum;
}
export interface IQuarterly {
  startDate: Date | null | string;
  payDate: Date | null | string;
  endDate: Date | null | string;
  paydateWeekendAdjustmentType: number | undefined;
  paydateBankingHolidayAdjustmentType: number | undefined;
}
export interface IPayPeriod {
  id?: number;
  calendarEventId?: number;
  startDate?: Date;
  endDate?: Date;
  payDate?: Date;
  processingDate?: Date;
  payPeriodCalendarStateType?: number;
  isActualPayDate?: boolean;
  displayName?: string;
  periodNumber?: number;
}
export interface IEffectivePayPeriod {
  id: number;
  startDate: Date | string;
  endDate: Date | string;
}

export enum MonthlyEndDatePatternTypeEnum {
  notDefined = 0,
  lastDateOfMonth = 1,
  thirtyDaysFromStartDate = 2,
}

export enum PayGroupStatusTypeEnum {
  NotDefined = 0,
  Active = 1,
  Deleted = 2,
  Disabled = 3,
}
export enum PayGroupFrequencyTypeEnum {
  NotDefined = 0,
  Weekly = 1,
  Biweekly = 2,
  Semimonthly = 3,
  Monthly = 4,
  Quarterly = 5,
}
export enum PayGroupFrequencyValueEnum {
  Weekly = 40,
  BiWeekly = 80,
  SemiMonthly = 86.67,
  Monthly = 173.33,
}
export enum StandardHoursPerWeekValueEnum {
  NotDefined = 0,
  Primary = 40,
  Secondary = 37.5,
}
export enum PayGroupWeekNumberEnum {
  ZeroWeek = 0,
  FirstWeek = 1,
  SecondWeek = 2,
  ThirdWeek = 3,
  FourthWeek = 4,
  FifthWeek = 5,
}
export interface IOptionList {
  key: number;
  value: string | number;
}

export enum BuildCalendarTypeEnum {
  OpenCalendar = 0,
  BuildCalendar = 1,
}

export enum PayPeriodCalendarStateTypeEnum {
  notDefined = 0,
  completed = 1,
  processing = 2,
  current = 3,
  upcoming = 4,
  skipped = 5,
}
export enum MonthlyPayPeriodTypeEnum {
  NotDefined = 0,
  Standard = 1,
  NonStandard = 2,
  Yearly = 3,
}
export enum SemiMonthlyPayPeriodTypeEnum {
  NotDefined = 0,
  Standard = 1,
  NonStandard = 2,
}
export enum MonthlyEndDatePatternTypeEnum {
  NotDefined = 0,
  LastDateOfMonth = 1,
  ThirtyDaysFromStartDate = 2,
}

export enum MonthlyWithInCycleTypeEnum {
  FallsWithIn = 1,
  FallsOutside = 0,
  FallsBefore = 2,
}

export enum PaydateWeekendAdjustmentTypeEnum {
  NotDefined = 0,
  FridayBefore = 1,
  MondayAfter = 2,
}

export enum PaydateBankingHolidayAdjustmentTypeEnum {
  NotDefined = 0,
  BusinessDayBeforeHoliday = 1,
  BusinessDayAfterHoliday = 2,
}

export interface IDisablePayGroupRequest {
  payGroupId: number;
}
export interface IDisablePayGroupResponse {
  validationResults: any;
}
export interface IEnablePayGroupRequest {
  payGroupId: number;
  payGroupName?: string;
}
export interface IEnablePayGroupResponse {
  payGroupId: number;
  validationResults: any;
}
export interface IManageAutoRunRequest {
  payGroupId: number | any;
  activateAutoRun: boolean;
  effectiveFromPayPeriod?: IPayPeriod;
}
export interface IManageAutoRunResponse {
  validationResults: any;
}
export interface IGetAutoRunSupportingDataRequest {
  payGroupId: number;
}
export interface IGetAutoRunSupportingDataResponse {
  effectivePayPeriodList: Array<IPayPeriod>;
  currentPeriodStateDate?: string;
  currentPeriodEndDate?: string;
  autoRunInitiatedPeriodStartDate?: string;
  autoRunInitiatedPeriodEndDate?: string;
  autoRunPeriodNumber: number;
  currentPeriodNumber: number;
  lastPeriodStartDate: string;
  lastPeriodEndDate: string;
  validationResults: ValidationResultsInterface;
}
export interface IGetCalendarRequestInterface {
  payGroupId: number;
  startDate?: string;
  endDate?: string;
  buildCalendar: boolean;
  includeOffCycle?: boolean;
  getByEmployeeId?: boolean;
  employeeId?: number;
  year?: number;
  monthList?: number[];
}

export interface IGetCalendarResponseInterface {
  payPeriodList: IPayPeriodInterface[];
  bankingHolidayList: Array<IBankingHolidayInterface>;
  validationResults: any;
}

export interface IBankingHolidayInterface {
  name: string;
  date: string;
}

export interface IPayPeriodInterface {
  id: number;
  calendarEventId: number;
  startDate: string;
  endDate: string;
  payDate: string;
  processingDate: string;
  payPeriodCalendarStateType: PayPeriodCalendarStateTypeEnum;
  isActualPayDate: boolean;
  isOffCycle?: boolean;
  actualPayDate?: string;
  periodNumber?: number;
}

export interface IGetEmployeeListRequest {
  payGroupId: number | string;
  searchByName?: string;
}

export interface IEmployee {
  id: number;
  firstName: string;
  lastName: string;
}
export interface IGetEmployeeListResponse {
  totalCount: number;
  employeeList: Array<IEmployee>;
  validationResults: any;
}

export interface DeletePaygroupRequest {
  payGroupId: number;
}
export interface DeletePaygroupResponse {
  validationResults: any;
}

export interface ValidateCompanyFirstPayrollRequest {
  companyId: number;
  payPeriodFriendlystatusType: PayPeriodFriendlyStatusTypeEnum;
  payGroupId?: number;
}

export interface ValidateCompanyFirstPayrollResponse {
  isValid: boolean;
  validationResults: IValidationResults;
}

export enum PayPeriodFriendlyStatusTypeEnum {
  NotDefined = 0,
  NotApproved,
  Approved,
  Finalized,
}

export interface IGetPayPeriodListRequest {
  payGroupId: number;
  getByPayPeriodFriendlyStatusType?: boolean;
  payPeriodFriendlyStatusType?: PayPeriodFriendlyStatusTypeEnum;
  isAscending?: boolean;
  getForPoEEmployee?: boolean;
  employeeId?: number;
  currentPoEId?: number;
  skip?: number;
  take?: number;
}

export interface IGetPayPeriodListResponse {
  payPeriodList: IPayPeriodInterface[];
  validationResults: IValidationResults;
}
export interface IPayPeriodDate {
  payPeriodNumber: number;
  startDate: Date;
  endDate: Date;
  payDate: Date;
  status: string;
}
export interface IPayGroupDetail {
  id: number;
  name: string;
  frequencyType: string;
  current: IPayPeriodDate;
  upComing: IPayPeriodDate;
  effective: IPayPeriodDate;
  autoRunModfiedDate: string;
  isAutoRun: boolean;
}
export interface IGetPayGroupTransferDetailRequest {
  fromPayGroupId?: number;
  toPayGroupId?: number;
}
export interface IGetPayGroupTransferDetailResponse {
  payGroupDetailList: Array<IPayGroupDetail>;
  validationResults: IValidationResults;
}
export interface IChangePersonPayGroupRequest {
  companyId: number;
  personId: number;
  sourcePayGroupId: number;
  targetPayGroupId: number;
}

export interface IChangePersonPayGroupResponse {
  validationResults: IValidationResults;
}

export enum StepTypeEnum {
  SelectPeople = 0,
  SelectNewPayGroup = 1,
  ReviewAndConfirm = 2,
}

export enum MovePeopleTypeEnum {
  NotDefined = 0,
  MoveOneOrMorePeople = 1,
  MoveAllPeople = 2,
}

export interface IGetAutoRunStatusRequest {
  companyId: number;
}
export interface IGetAutoRunStatusResponse {
  validationResults: IValidationResults;
  isAutoRunPaygroupPresent: boolean;
}

export interface GetPayGroupLiteListRequestInterface {
  companyId: number;
}

export interface GetPayGroupLiteListResponseInterface {
  payGroupLiteList: Array<PayGroupLiteInterface>;
  validationResults: IValidationResults;
}

export interface PayGroupLiteInterface {
  id: number;
  name: string;
}

export enum GetPayGroupLiteListEnum {
  invalidCompanyId = 0,
}

export enum PayPeriodFriendlyStatusTypeString {
  NotApproved = 'NotApproved',
  Approved = 'Approved',
  Deleted = 'Deleted',
  Rejected = 'Rejected',
}
export interface PersistPayGroupFromAlfredRequestInterface {
  payGroup: IPayGroup;
  validationScopeType?: ValidationScopeTypeEnum;
  isMigratedPaygroup?: boolean;
}

export interface PersistPayGroupFromAlfredResponseInterface {
  payGroupId: number;
  validationResults: IValidationResults;
}
